<template>
  <div>
    <v-alert
      v-for="(a, index) in alerts"
      :type="a.type"
      :key="index"
      dense
      dismissible
      @input="onClose"
    >
      {{ a.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'AlertComponent',
  computed: {
    alerts() {
      return this.$store.state.alert.alerts;
    },
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch('alert/clear');
    },
  },
  methods: {
    onClose() {
      this.$store.dispatch('alert/clear');
    },
  },
};
</script>
