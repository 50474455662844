<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <h2 class="rwygrey--text font-weight-light">
        {{ title }}
      </h2>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div>
        {{ description }}
      </div>
      <div class="mt-5">
        <h3 class="rwblue--text">Look inside this occupation!</h3>
        <v-row no-gutters>
          <v-btn
            text
            class="mt-2 py-0 px-2 rwblue--text"
            @click="showTasks = !showTasks"
          >
            <v-icon v-if="!showTasks">
              mdi-chevron-right
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
            Tasks
          </v-btn>
          <v-expand-transition>
            <div
              class="mt-5"
              v-show="showTasks"
              transition="scroll-y-transition"
            >
              <h4>Do these look interesting to you?</h4>
              <v-list subheader>
                <v-list-item v-for="t in tasks" :key="t">
                  <v-list-item-icon class="mr-5">
                    <v-icon color="primary" medium>
                      mdi-head-lightbulb-outline
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="text-body-1">
                    {{ t }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-expand-transition>
        </v-row>
        <v-row no-gutters>
          <v-btn
            text
            class="mt-2 py-0 px-2 rwblue--text"
            @click="showJobTitles = !showJobTitles"
            large
          >
            <v-icon v-if="!showJobTitles">
              mdi-chevron-right
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
            Job Titles
          </v-btn>

          <QuizResultSubList
            :showContent="showJobTitles"
            subtitle="These are the jobs found inside this occupation. Which fit you best?"
            description="INSTRUCTIONS: Look up 5-10 job postings and reflect. As you read job postings, remember you may not have all the skills just yet. You can always get additional training."
            :items="related_job_titles"
          />
        </v-row>
        <v-row no-gutters>
          <v-btn
            text
            class="mt-2 py-0 px-2 rwblue--text"
            @click="showTechnologies = !showTechnologies"
            large
          >
            <v-icon v-if="!showTechnologies">
              mdi-chevron-right
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
            Hot Technologies
          </v-btn>
          <QuizResultSubList
            :showContent="showTechnologies"
            subtitle="These are the Hot Technologies found inside this occupation. Which fit you best?"
            description="INSTRUCTIONS: Look up each Hot Technology to learn more about it, watch descriptive videos, and find additional training or certifications."
            description2="FUN FACT: Building STEM/STREAM skills adds to future job security (STREAM = Science, Technology, wRiting, Engineering, Art and Math)."
            :items="emerging_technologies"
          />
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import QuizResultSubList from '@/components/quiz/QuizResultSubList.vue';

export default {
  name: 'QuizResults',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    related_job_titles: {
      type: Array,
      required: true,
    },
    emerging_technologies: {
      type: Array,
      required: true,
    },
    tasks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showTasks: false,
      showJobTitles: false,
      showTechnologies: false,
    };
  },
  components: {
    QuizResultSubList,
  },
};
</script>
