<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="mt-5">
        <div v-if="displayResults">
          <h1 class="rwygrey--text font-weight-light">
            Test Results
            <span style="font-size: .5em">
              (Test Attempts: {{ quizResults.attempts }} of
              {{ quizResults.max_attempts }})
            </span>
          </h1>
          <h3 class="rwygrey--text font-weight-light"></h3>
          <h2 class="rwblue--text font-weight-light mt-2">
            These are your top Occupations!
          </h2>
          <h3 class="rwblue--text font-weight-light mt-2">
            Click to read the following summaries and determine which category
            of work would interest you:
          </h3>

          <v-expansion-panels accordion flat multiple>
            <QuizResults
              v-for="r in quizResults.results"
              v-bind:key="r.onetsoc_code"
              v-bind:title="r.occupation_title"
              v-bind:description="r.occupation_description"
              v-bind:related_job_titles="r.related_job_titles"
              v-bind:emerging_technologies="r.emerging_technologies"
              v-bind:tasks="r.tasks"
            ></QuizResults>
          </v-expansion-panels>

          <v-container class="text-center">
            <v-btn
              color="primary"
              rounded
              x-large
              elevation="5"
              :to="{ name: 'JobMarketStep1' }"
              class="mb-5 ml-5"
              v-if="quizResults.attempts < quizResults.max_attempts"
            >
              RETAKE TEST
            </v-btn>
            <v-btn
              color="primary"
              rounded
              x-large
              elevation="5"
              :to="{ name: 'WhatsNext' }"
              class="mb-5 ml-5"
            >
              WHAT’S NEXT?
            </v-btn>
          </v-container>
        </div>

        <div v-if="displayButton" class="text-center">
          <v-img
            contain
            max-height="200"
            src="/images/man-taking-test.png"
            class="mb-5"
          >
            <template v-slot:placeholder>
              <v-row class="ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <h1 class="rwygrey--text font-weight-light mb-5">Ok, here we go!</h1>
          <v-btn
            color="secondary mb-16"
            rounded
            x-large
            :to="{ name: 'JobMarketStep1' }"
          >
            START THE TEST!
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from '@/templates/AppTemplate.vue';
import QuizResults from '@/components/quiz/QuizResults.vue';
import { quizService } from '../services';

export default {
  name: 'JobMarketSurveyView',
  data() {
    return {
      loading: false,
      displayButton: false,
      buttonText: '',
      displayResults: false,
      quizResults: [],
    };
  },
  components: {
    AppTemplate,
    QuizResults,
  },
  mounted() {
    this.$store.dispatch('progress/loading');
    quizService.getQuizStatus().then(
      (results) => {
        if (results.status == 'complete') {
          this.loadQuizResults();
        } else {
          this.displayButton = true;
          this.$store.dispatch('progress/clear');
        }
      },
      (error) => {
        this.$store
          .dispatch('alert/error', `Error loading current status: ${error}`)
          .then(() => {
            this.$store.dispatch('progress/clear');
          });
      },
    );
  },
  methods: {
    loadQuizResults() {
      this.$store.dispatch('progress/loading');
      quizService
        .getResults()
        .then(
          (results) => {
            this.quizResults = results;
            this.displayResults = true;
          },
          (error) => {
            this.$store.dispatch(
              'alert/error',
              `Error loading test results: ${error}`,
            );
          },
        )
        .then(() => {
          this.$store.dispatch('progress/clear');
        });
    },
  },
  metaInfo: {
    title: 'Test Results',
  },
};
</script>
