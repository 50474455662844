<template>
  <v-list-item two-line>
    <v-list-item-content>
      <v-list-item-title class="ps-0 mb-0 pb-0">
        <v-checkbox
          v-model="selectedVal"
          :id="choice_id"
          :value="selectedVal"
          :label="name"
          class="mb-0 pb-0"
          color="red"
          v-on:click="updateSelected"
        ></v-checkbox>
      </v-list-item-title>
      <v-list-item-subtitle class="ps-2 text-wrap mt-0 pt-0">{{
        description
      }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'QuizChoice',
  props: {
    choice_id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedVal: this.selected,
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      this.updateSelected();
    });
  },
  methods: {
    updateSelected: function() {
      let data = {
        element: this.$props.choice_id,
        selected: this.selectedVal,
      };

      this.$emit('update-selected', data);
    },
  },
};
</script>
