import {
  userService
} from '../services';
import {
  router
} from '../helpers';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = user ? {
  authenticated: true,
  status: {
    loggedIn: true
  },
  user
} : {
  authenticated: false,
  status: {},
  user: null
};

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    register({
      dispatch,
      commit
    }, {
      email,
      first_name,
      last_name,
      password
    }) {
      commit('registerRequest', {
        email,
      });

      userService.register(email, first_name, last_name, password)
        .then(
          results => {
            commit('registerSuccess', results);
          },
          error => {
            commit('registerFailure', error);
            dispatch('alert/error', error, {
              root: true
            });
          }
        );
    },
    login({
      dispatch,
      commit
    }, {
      email,
      password
    }) {
      commit('loginRequest', {
        email
      });

      userService.login(email, password)
        .then(
          user => {
            commit('loginSuccess', user);
            router.push({
              name: "JobMarket"
            });
          },
          errors => {
            commit('loginFailure', errors);
            for (let k in errors) {
              dispatch('alert/error', errors[k][0], {
                root: true
              });
            }
          }
        );
    },
    logout({
      commit
    }) {
      userService.logout();
      commit('logout');
    },
    forgotPassword({
      dispatch,
      commit
    }, {
      email,
    }) {
      commit('forgotPassword', {
        email,
      });

      userService.forgotPassword(email)
        .then(
          results => {
            commit('forgotPasswordSuccess', results);

            dispatch('alert/success', "Please check your email to continue with reseting your password.", {
              root: true
            });
          },
          error => {
            commit('forgotPaswordFailure', error);
            dispatch('alert/error', error, {
              root: true
            });
          }
        );
    },
    resetPassword({
      dispatch,
      commit
    }, {
      email,
      token,
      password,
    }) {
      commit('resetPassword', {
        email,
      });

      userService.resetPassword(email, token, password)
        .then(
          results => {
            commit('resetPasswordSuccess', results);

            dispatch('alert/success', "Your password has been updated!", {
              root: true
            });
          },
          error => {
            commit('resetPaswordFailure', error);
            dispatch('alert/error', error, {
              root: true
            });
          }
        );
    },
    self({
      commit
    }) {
      commit('self');

      userService.self()
        .then(
          (results) => {
            if (!results) {
              commit('selfFailure', 'not logged in');
            } else {
              commit('selfSuccess');
            }
          },
          error => {
            commit('selfFailure', error);
          }
        );
    },
    isLoggedIn({
      commit
    }) {
      commit('isLoggedIn');

      if (user && user.token) {
        commit('selfSuccess');
      } else {
        commit('selfFailure', 'not logged in');
      }
    },
  },
  mutations: {
    registerRequest(state) {
      state.status = {
        registering: true
      };
    },
    registerSuccess(state) {
      state.status = {
        registered: true
      };
    },
    registerFailure(state) {
      state.status = null;
    },
    loginRequest(state, user) {
      state.status = {
        loggingIn: true
      };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.authenticated = true;
      state.status = {
        loggedIn: true
      };
      state.user = user;
    },
    loginFailure(state) {
      state.authenticated = false;
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.authenticated = false;
      state.status = {};
      state.user = null;
    },
    forgotPassword(state) {
      state.authenticated = false;
      state.status = {
        forgotPasswordRequest: true
      };
    },
    forgotPasswordSuccess(state) {
      state.authenticated = false;
      state.status = {
        forgotPasswordent: true,
      }
    },
    forgotPaswordFailure(state) {
      state.authenticated = false;
      state.status = {};
    },
    resetPassword(state) {
      state.authenticated = false;
      state.status = {
        resetPasswordRequest: true
      };
    },
    resetPasswordSuccess(state) {
      state.authenticated = false;
      state.status = {
        resetPasswordent: true,
      }
    },
    resetPaswordFailure(state) {
      state.authenticated = false;
      state.status = {};
    },
    getSelf(state) {
      state.authenticated = false;
      state.status = {};
    },
    selfSuccess(state) {
      state.authenticated = true;
      state.status = {
        loggedIn: true
      };
    },
    selfFailure(state) {
      state.authenticated = false;
      state.status = {};
    }
  },
}
