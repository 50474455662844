<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      justify="center"
      align="center"
      :class="{
        'home-banner-lg': $vuetify.breakpoint.mdAndUp,
        'home-banner-sm': $vuetify.breakpoint.smOnly,
        'home-banner-xs': $vuetify.breakpoint.xsOnly,
      }"
      :style="{ 'background-image': 'url(/images/home-banner-bg.png)' }"
    >
      <v-col>
        <v-row justify="center" align="start" no-gutters>
          <v-col cols="12" sm="3" order="2" order-sm="1">
            <HeaderLogo />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="9"
            class="text-right pt-5"
            order="1"
            order-sm="2"
          >
            <TopMenu />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="1"></v-col>
          <v-col cols="12" sm="5">
            <v-row align="center" justify="center" class="mt-10" no-gutters>
              <v-col cols="1">
                <v-img src="/images/down-arrow.png" />
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-5" no-gutters>
              <v-col class="text-center">
                <h1 class="rwygrey--text font-weight-light">
                  Where do you fit<br class="hidden-md-and-down" />
                  in America’s job market?
                </h1>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-10" no-gutters>
              <v-col class="text-center">
                <h2 class="rwygrey--text font-weight-regular">
                  Don’t settle for career assessments<br
                    class="hidden-md-and-down"
                  />
                  with bland results.
                </h2>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" class="mt-5" no-gutters>
              <v-col class="text-center">
                <h2 class="rwygrey--text font-weight-regular">
                  Let’s find where you really fit so you<br
                    class="hidden-md-and-down"
                  />
                  can build a sustainable career.
                </h2>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" no-gutters>
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  rounded
                  x-large
                  :to="{ name: 'Register' }"
                >
                  <v-icon left color="white">
                    mdi-star-shooting
                  </v-icon>
                  TAKE THE TEST NOW
                </v-btn>
              </v-col>
            </v-row>
            <v-row align="center" justify="center" no-gutters>
              <v-col class="text-center">
                <v-btn
                  color="primary"
                  plain
                  rounded
                  x-large
                  :to="{ name: 'About' }"
                >
                  LEARN MORE
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-1.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-2.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-3.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-4.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-5.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-6.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-7.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-8.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-9.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-10.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-11.png" />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-img src="/images/home-person-12.png" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.home-banner-lg {
  background: no-repeat center top;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.home-banner-sm {
  background: no-repeat center top;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}
.home-banner-xs {
  background: no-repeat 30% 50%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}
</style>
<script>
import HeaderLogo from '@/components/ui/HeaderLogo.vue';
import TopMenu from '@/components/ui/TopMenu.vue';

export default {
  name: 'HomeView',
  components: {
    HeaderLogo,
    TopMenu,
  },
  methods: {},
  metaInfo: {
    title: 'Welcome',
  },
};
</script>
