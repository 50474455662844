import {
  authHeader,
  router,
} from '../helpers';

export const paymentsService = {
  getIntent,
  self,
};

function getIntent() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/payments/intent`, requestOptions)
    .then(handleResponse);
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        router.push({
          name: "Logout"
        });
      } else if (response.status === 409) {
        return Promise.reject(data);
      }

      const error = (data && data.message) || data;
      return Promise.reject(error);
    }

    return data;
  });
}
