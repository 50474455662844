import {
  userService
} from '../services';

export const self = {
  namespaced: true,
  state: {
    self: {}
  },
  actions: {
    getSelf({
      commit
    }) {
      commit('getSelfRequest');

      userService.self()
        .then(
          self => commit('getSelfSuccess', self),
          error => commit('getSelfFailure', error),
        );
    }
  },
  mutations: {
    getSelfRequest(state) {
      state.self = {
        loading: true
      };
    },
    getSelfSuccess(state, self) {
      state.self = {
        items: self
      };
    },
    getSelfFailure(state, error) {
      state.self = {
        error
      };
    },
  },
}
