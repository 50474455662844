<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="pa-5">
        <h1 class="rwygrey--text font-weight-light">Privacy Policy</h1>

        <p>
          This Privacy Policy describes your privacy rights regarding our
          collection, use, storage, sharing and protection of your personal
          information. It applies to the findyourfuturenow.com website and all
          related sites, applications, services and tools regardless of how you
          access or use them.
        </p>

        <p>
          You accept this Privacy Policy when you sign up for, access, or use
          our services, content, features, technologies or functions offered on
          our website and all related sites, applications, and services
          (collectively “Find Your Future Now Services”). We may amend this
          policy at any time by posting a revised version on our website. The
          revised version will be effective at the time we post it. In addition,
          if the revised version includes a substantial change, we will provide
          you with 30 days’ prior notice by emailing you a notice of the change
          at the email address you provided during service sign up.
        </p>

        <h2 class="rwygrey--text font-weight-light">
          How we collect information about you
        </h2>

        <p>
          When you sign up for service through the findyourfuturenow.com
          website, you voluntarily provide us with your contact information
          including your email address as well as your financial information.
          This information is collected so that we can accurately perform
          services and so that we can communicate with you regarding your test
          results.
        </p>

        <p>
          If you open a Find Your Future Now account, we may collect the
          following types of information:
        </p>
        <ul>
          <li>
            Contact information, such as your name, phone, email and other
            similar information.
          </li>
          <li>
            Financial information, such as the full bank account numbers and/or
            credit card numbers that you give us when you use Find Your Future
            Now Services.
          </li>
        </ul>

        <p>
          You may choose to provide us with access to certain personal
          information stored by third parties such as social media sites (e.g.,
          Facebook and Twitter). This is done when you voluntarily “like” our
          Facebook Page or when you voluntarily “follow” on Twitter Page. The
          information we may receive varies by site and is controlled by that
          site. By associating an account managed by a third party with your
          Find Your Future Now account and authorizing Find Your Future Now to
          have access to this information, you agree that Find Your Future Now
          may collect, store and use this information in accordance with this
          Privacy Policy.
        </p>

        <p>
          We may also collect additional information from or about you in other
          ways, such as through contact with our customer support team, results
          when you respond to a survey and from interactions with
          providers/representatives of Find Your Future Now.
        </p>

        <h2 class="rwygrey--text font-weight-light">
          How we protect and store personal information
        </h2>

        <p>
          Throughout this policy, we use the term “personal information” to
          describe information that can be associated with a specific person and
          can be used to identify that person. We do not consider personal
          information to include information that has been made anonymous so
          that it does not identify a specific user.
        </p>

        <p>
          We store and process your personal information on our computers in the
          US. We protect your information using physical, technical, and
          administrative security measures to reduce the risks of loss, misuse,
          unauthorized access, disclosure and alteration. Some of the safeguards
          we use are physical access controls to your data and information
          access authorization controls.
        </p>

        <h2 class="rwygrey--text font-weight-light">
          How we share personal information with other parties
        </h2>

        <p>We may share your personal information with:</p>

        <ul>
          <li>
            Service providers who help with our business operations such as
            fraud prevention, accounting, marketing and technology services.
            These service providers only use your information in connection with
            the services they perform for us and not for their own benefit.
          </li>
          <li>
            Companies that we plan to merge with or are acquired by. (Should
            such a combination occur, we will require that the new combined
            entity follow this privacy policy with respect to your personal
            information. If your personal information could be used contrary to
            this policy, you will receive prior notice.)
          </li>
          <li>
            Law enforcement, government officials, or other third parties
            pursuant to a subpoena, court order, or other legal process or
            requirement applicable to Find Your Future Now or one of its
            affiliates; when we need to do so to comply with law or credit card
            rules; or when we believe, in our sole discretion, that the
            disclosure of personal information is necessary to prevent physical
            harm or financial loss, to report suspected illegal activity or to
            investigate violations of our Terms of Service.
          </li>
          <li>Other third parties with your consent or direction to do so.</li>
        </ul>

        <p>
          Find Your Future Now will not sell or rent any of your personal
          information to third parties for their marketing purposes and only
          shares your personal information with third parties as described in
          this policy.
        </p>

        <h2 class="rwygrey--text font-weight-light">
          How you can access or change your personal information
        </h2>

        <p>
          If you close your Find Your Future Now account, we will mark your
          account in our database as “Closed,” but may retain personal
          information from your account to collect any fees owed, resolve
          disputes, troubleshoot problems, assist with any investigations,
          prevent fraud, enforce our Terms of Service, or take other actions as
          required or permitted by law.
        </p>

        <h2 class="rwygrey--text font-weight-light">
          How you can contact us about privacy questions
        </h2>

        <p>
          If you have questions or concerns regarding this policy, you should
          contact us by emailing support@findyourfuturenow.com.
        </p>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from '@/templates/AppTemplate.vue';

export default {
  name: 'PrivacyPolicyView',
  components: {
    AppTemplate,
  },
  metaInfo: {
    title: 'Privacy Policy',
  },
};
</script>
