<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center" class="mb-16">
      <v-col cols="12" sm="6" class="pa-5">
        <h1 class="rwygrey--text font-weight-light">Forgot Password</h1>
        <v-form
          ref="forgotPassword"
          v-model="valid"
          lazy-validation
          class="my-5"
        >
          <v-text-field
            v-model="email"
            :counter="70"
            label="Email Address"
            :rules="rules.email"
            :autofocus="true"
            maxlength="70"
            required
            v-on:keydown.enter.prevent="forgotPassword"
          />
        </v-form>
        <v-btn
          color="secondary"
          class="white--text mr-5"
          :disabled="!valid"
          @click="forgotPassword"
        >
          Reset Password
        </v-btn>
        <router-link :to="{ name: 'Login' }" class="mr-5">
          Login
        </router-link>
        <router-link :to="{ name: 'Register' }">
          Need an account?
        </router-link>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from '@/templates/AppTemplate.vue';

export default {
  name: 'ForgotPasswordView',
  data: () => ({
    email: null,
    valid: true,
    rules: {
      email: [
        (v) => !!v || 'email is required',
        (v) => /.+@.+/.test(v) || 'email must be valid',
        (v) =>
          (v && v.length <= 128) ||
          'the email must be no longer than 128 characters',
      ],
    },
  }),
  components: {
    AppTemplate,
  },
  methods: {
    forgotPassword() {
      if (this.$refs.forgotPassword.validate()) {
        this.$store.dispatch('progress/loading');

        this.$store
          .dispatch('authentication/forgotPassword', {
            email: this.email,
          })
          .then(() => {
            this.$store.dispatch('progress/clear');
          });
      }
    },
  },
  metaInfo: {
    title: 'Forgot Password',
  },
};
</script>
