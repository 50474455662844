<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="mt-5 pa-5">
        <h1 class="rwygrey--text font-weight-light">What's Next</h1>
        <ol class="py-5">
          <li>
            <h3 class="rwygrey--text font-weight-light mb-5">
              Make a Magic Sheet:
            </h3>
            <p>
              In a separate window, open a blank document and create three
              sections, then copy and paste each of the TASKS from your top
              occupation into the right category.
            </p>

            <ul class="mb-5">
              <li>HAVE EXPERIENCE</li>
              <li>NEED EXPERIENCE</li>
              <li>NOT INTERESTED</li>
            </ul>
          </li>
          <li>
            <h3 class="rwygrey--text font-weight-light mb-5">
              Do Job Research (not Job Search):
            </h3>
            <p>
              Look up 10-15 jobs which can be located anywhere in the country.
              You are just looking for information. Add specific technologies,
              training and certificates to your Magic Sheet.
            </p>
          </li>
          <li>
            <h3 class="rwygrey--text font-weight-light mb-5">
              Find additional training:
            </h3>
            <p>There are many places to find additional training including:</p>
            <ul class="mb-5">
              <li>University Extension or Continuing Education programs</li>
              <li>Certifications through professional organizations</li>
              <li>
                Specific certifications through technology providers (Excel
                Certified through Microsoft, Google Analytics Certified through
                Google, Facebook Ads Certified through Facebook, Twitter Ads
                Certified through Twitter Flight School, etc.)
              </li>
            </ul>
          </li>
          <li>
            <h3 class="rwygrey--text font-weight-light mb-5">
              Build your resume:
            </h3>
            <p>
              Use the information from the HAVE EXPERIENCE section to build a
              resume reflecting your qualifications, academic and career
              training, and career interests.
            </p>
          </li>
          <li>
            <h3 class="rwygrey--text font-weight-light mb-5">
              Apply vigorously:
            </h3>
            <p>
              Don’t judge a job posting by what you read. Hiring managers
              consider at resumes from junior, mid and senior levels for any
              given posting. Often, you will be invited to interview and then
              redirected to the proper department. Applying to 50 jobs should
              yield you a few interviews.
            </p>
          </li>
        </ol>
      </v-col>
      <v-col cols="12" class="pa-5 text-center">
        <h1 class="rwygrey--text font-weight-light">
          GOOD LUCK!
        </h1>
      </v-col>
      <v-col cols="12" class="pa-5 text-center">
        <v-btn
          color="secondary"
          class="white--text ma-2"
          @click="$router.go(-1)"
        >
          Go Back
        </v-btn>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from '@/templates/AppTemplate.vue';
import { quizService } from '../services';

export default {
  name: 'WhatsNextView',
  data() {
    return {
      loading: false,
      displayButton: false,
      buttonText: '',
      displayResults: false,
      quizResults: [],
    };
  },
  components: {
    AppTemplate,
  },
  mounted() {
    this.$store.dispatch('progress/loading');
    quizService
      .getQuizStatus()
      .then(
        (results) => {
          if (results.status == 'complete') {
            this.loadQuizResults();
          } else {
            this.displayButton = true;
          }
        },
        (error) => {
          this.$store.dispatch(
            'alert/error',
            `Error loading current status: ${error}`,
          );
        },
      )
      .then(() => {
        this.$store.dispatch('progress/clear');
      });
  },
  methods: {
    loadQuizResults() {
      this.$store.dispatch('progress/loading');
      quizService
        .getResults()
        .then(
          (results) => {
            this.quizResults = results;
            this.displayResults = true;
          },
          (error) => {
            this.$store.dispatch(
              'alert/error',
              `Error loading test results: ${error}`,
            );
          },
        )
        .then(() => {
          this.$store.dispatch('progress/clear');
        });
    },
  },
  metaInfo: {
    title: 'Test Results',
  },
};
</script>
