<template>
  <v-list dense flat>
    <v-expand-transition>
      <div v-show="showContent" transition="scroll-y-transition ma-5">
        <p class="primary--text text-decoration-underline font-weight-light">
          {{ subtitle }}
        </p>
        <p class="secondary--text font-weight-light">
          {{ description }}
        </p>
        <p class="secondary--text font-weight-light font-italic">
          {{ description2 }}
        </p>
        <v-list-item-group>
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            class="pa-0 wrap-text"
          >
            <v-list-item-icon class="mr-3">
              <v-icon color="secondary">mdi-check-all</v-icon>
            </v-list-item-icon>
            <v-list-item-content align="left">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </div>
    </v-expand-transition>
  </v-list>
</template>

<style scoped>
.wrap-text {
  -webkit-line-clamp: unset !important;
}
</style>

<script>
export default {
  name: 'QuizResultsSubList',
  props: {
    showContent: {
      type: Boolean,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    description2: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
