export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem('user'));

  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  
  if (user && user.token) {
    headers.append('Authorization', 'Bearer ' + user.token);
  }
  return headers;
}
