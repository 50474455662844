<template>
  <v-row justify="end" class="rwygrey--text" no-gutters>
    <v-col class="hidden-md-and-down pa-0">
      <ul>
        <li class="d-inline mx-2 pa-1 text-h6">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'Home' }"
          >
            HOME
          </v-btn>
        </li>
        <li class="d-inline mx-2 pa-1 text-h6" v-if="!authenticated">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'Register' }"
          >
            TAKE THE TEST NOW
          </v-btn>
        </li>
        <li class="d-inline mx-2 pa-1 text-h6">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'About' }"
          >
            SUSTAINABLE CAREERS
          </v-btn>
        </li>
        <li class="d-inline mx-2 pa-1 text-h6" v-if="!authenticated">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'GetReady' }"
          >
            ABOUT
          </v-btn>
        </li>
        <li class="d-inline mx-2 pa-1 text-h6" v-if="authenticated">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'JobMarket' }"
          >
            TEST RESULTS
          </v-btn>
        </li>
        <li class="d-inline mx-2 pa-1 text-h6" v-if="!authenticated">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'Login' }"
          >
            SIGN IN
          </v-btn>
        </li>
        <li class="d-inline mx-2 pa-1 text-h6" v-if="authenticated">
          <v-btn
            x-large
            text
            plain
            class="pa-0"
            active-class="no-active"
            :to="{ name: 'Logout' }"
          >
            SIGN OUT
          </v-btn>
        </li>
      </ul>
    </v-col>
    <v-col class="hidden-md-and-up pa-0">
      <v-menu offset-y transition="scale-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            x-large
            v-bind="attrs"
            v-on="on"
          ></v-app-bar-nav-icon>
        </template>
        <v-list flat>
          <v-list-item :to="{ name: 'Home' }">
            <v-list-item-title>HOME</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Home' }" v-if="!authenticated">
            <v-list-item-title>TAKE THE TEST NOW</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'About' }">
            <v-list-item-title>SUSTAINABLE CAREERS</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'GetReady' }" v-if="!authenticated">
            <v-list-item-title>ABOUT</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'JobMarket' }" v-if="authenticated">
            <v-list-item-title>TEST RESULTS</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Login' }" v-if="!authenticated">
            <v-list-item-title>SIGN IN</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'Logout' }" v-if="authenticated">
            <v-list-item-title>SIGN OUT</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>

<style scoped>
.v-btn--active.no-active::before {
  opacity: 0 !important;
}
</style>
<script>
export default {
  name: 'TopMenu',
  computed: {
    authenticated() {
      return this.$store.state.authentication.authenticated;
    },
  },
};
</script>
