<template>
  <router-link class="white--text" :to="{ name: 'Home' }">
    <v-img
      class="mt-5"
      :class="{
        'ml-5': $vuetify.breakpoint.mdAndUp,
        'mx-5': $vuetify.breakpoint.smAndDown,
      }"
      src="/images/header-logo-fyf.png"
    />
  </router-link>
</template>

<script>
export default {
  name: 'HeaderLogo',
};
</script>
