import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'

import {
  alert
} from './alert.module';
import {
  authentication
} from './authentication.module';
import {
  progress
} from './progress.module';
import {
  self
} from './self.module';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

export const store = new Vuex.Store({
  modules: {
    alert,
    authentication,
    progress,
    self,
  },
  plugins: [vuexLocal.plugin],
});
