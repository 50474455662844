import {
  authHeader,
  router,
} from '../helpers';

export const quizService = {
  getKnowledges,
  saveKnowledges,
  getSkills,
  saveSkills,
  getWorkActivities,
  saveWorkActivities,
  getQuizStatus,
  finishQuiz,
  getResults,
  self,
};

function getKnowledges() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/knowledges`, requestOptions)
    .then(handleResponse);
}

function saveKnowledges(knowledges) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      knowledges: knowledges,
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/knowledges`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function getSkills() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/skills`, requestOptions)
    .then(handleResponse);
}

function saveSkills(skills) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      skills: skills,
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/skills`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function getWorkActivities() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/work-activities`, requestOptions)
    .then(handleResponse);
}

function saveWorkActivities(work_activities) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      work_activities: work_activities,
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/work-activities`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function getQuizStatus() {
  const requestOptions = {
    cache: 'no-cache',
    method: 'GET',
    headers: authHeader(true),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/status`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function finishQuiz() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/results`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function getResults() {
  const requestOptions = {
    cache: 'no-cache',
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/job-market/quiz/results`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        router.push({
          name: "Logout"
        });
      } else if (response.status === 402) {
        router.push({
          name: "Register"
        });
      } else if (response.status === 409) {
        return Promise.reject(data);
      }

      const error = (data && data.message) || data.non_field_errors[0];
      return Promise.reject(error);
    }

    return data;
  });
}
