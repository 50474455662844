<template>
  <v-row no-gutters>
    <v-col class="primary pa-2"></v-col>
    <v-col class="accent pa-2"></v-col>
    <v-col class="secondary pa-2"></v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HeaderComponent',
};
</script>
