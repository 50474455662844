<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      justify="center"
      align="center"
      :class="{
        'home-banner-lg': $vuetify.breakpoint.mdAndUp,
        'home-banner-sm': $vuetify.breakpoint.smOnly,
        'home-banner-xs': $vuetify.breakpoint.xsOnly,
      }"
      :style="{ 'background-image': 'url(/images/get-ready-banner-bg.png)' }"
    >
      <v-col>
        <v-row justify="center" align="start" no-gutters>
          <v-col cols="12" sm="3" order="2" order-sm="1">
            <HeaderLogo />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="9"
            class="text-right pt-5"
            order="1"
            order-sm="2"
          >
            <TopMenu />
          </v-col>
        </v-row>
        <v-row no-gutters justify="end" align="end">
          <v-col cols="12">
            <v-row
              :class="{
                'mt-16 pt-16 justify-end align-end':
                  $vuetify.breakpoint.lgAndUp,
                'mt-10 justify-center align-center':
                  $vuetify.breakpoint.smAndDown,
              }"
              no-gutters
            >
              <v-spacer class="hidden-xs-only" />
              <v-col cols="5" justify="center" align="center" class="mb-16">
                <h1 class="rwygrey--text font-weight-light">
                  A career assessment<br class="hidden-md-and-down" />
                  designed for YOU!
                </h1>
                <v-img
                  src="/images/down-arrow.png"
                  max-width="30"
                  class="mb-16"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-col
        cols="12"
        sm="6"
        justify="center"
        align="center"
        class="pa-5"
        order="1"
      >
        <h2 class="rwygrey--text font-weight-light">
          SEE THE VISION
        </h2>
        <v-img
          src="/images/get-ready-top-10-occupations.png"
          width="50%"
          class="mb-5"
        />
        <h2 class="rwygrey--text font-weight-light">
          Your Top 10 Occupations will reveal the general career pathways that
          fit you.
        </h2>
      </v-col>
      <v-col cols="12" sm="6" justify="center" align="center" order="2">
        <v-img src="/images/get-ready-person-1.png" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        justify="center"
        align="center"
        order="4"
        order-sm="3"
      >
        <v-img src="/images/get-ready-person-2.png" />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        justify="center"
        align="center"
        order="3"
        order-sm="4"
        class="pa-5"
      >
        <h2 class="rwygrey--text font-weight-light mb-5">
          FIND WHERE YOU FIT
        </h2>
        <v-img
          src="/images/get-ready-with-specific-job-titles.png"
          width="50%"
          class="mb-5"
        />
        <h2 class="rwygrey--text font-weight-light">
          Learn the specific job titles within each occupation to make job
          searching much easier.
        </h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        justify="center"
        align="center"
        class="pa-5"
        order="5"
      >
        <h2 class="rwygrey--text font-weight-light mb-5">
          BUILD STRONG SKILLS
        </h2>
        <v-img
          src="/images/get-ready-emerging-technologies-find-training.png"
          width="50%"
          class="mb-5"
        />
        <h2 class="rwygrey--text font-weight-light">
          Review a list of Hot Technologies for each occupation so you know
          which skills are in high demand.
        </h2>
      </v-col>
      <v-col cols="12" sm="6" justify="center" align="center" order="6">
        <v-img src="/images/get-ready-person-3.png" />
      </v-col>
    </v-row>
    <v-row
      no-gutters
      justify="center"
      align="center"
      :class="{
        'person-4-lg': $vuetify.breakpoint.mdAndUp,
        'person-4-sm': $vuetify.breakpoint.smOnly,
        'person-4-xs': $vuetify.breakpoint.xsOnly,
      }"
      :style="{ 'background-image': 'url(/images/get-ready-person-4.png)' }"
    >
      <v-col cols="12">
        <v-row
          :class="{
            'mt-16 pt-16 justify-end align-end': $vuetify.breakpoint.lgAndUp,
            'mt-10 justify-center align-center': $vuetify.breakpoint.smAndDown,
          }"
          no-gutters
        >
          <v-spacer class="hidden-xs-only" />
          <v-col cols="5" justify="center" align="center" class="my-16">
            <h1 class="rwygrey--text font-weight-light">
              You will spend 20-30<br class="hidden-md-and-down" />
              minutes answering<br class="hidden-md-and-down" />
              questions to 3 tests:
            </h1>
            <v-img src="/images/down-arrow.png" max-width="30" class="mb-16" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12" sm="4" justify="center" align="center" class="pa-5">
        <v-img src="/images/get-ready-knowledge-areas.png" width="50%" />
        <h2 class="rwygrey--text font-weight-light">
          Knowledge Areas
        </h2>
      </v-col>
      <v-col cols="12" sm="4" justify="center" align="center" class="pa-5">
        <v-img src="/images/get-ready-skills.png" width="50%" />
        <h2 class="rwygrey--text font-weight-light">
          Skills
        </h2>
      </v-col>
      <v-col cols="12" sm="4" justify="center" align="center" class="pa-5">
        <v-img src="/images/get-ready-work-activities.png" width="50%" />
        <h2 class="rwygrey--text font-weight-light">
          Work Activities
        </h2>
      </v-col>
    </v-row>
    <HeaderComponent />
    <v-row justify="center" align="center" no-gutters>
      <v-col
        cols="12"
        sm="10"
        justify="center"
        align="center"
        class="px-5 py-16"
      >
        <h2 class="rwygrey--text font-weight-light mb-5">
          Cut through the clutter and see where you fit.
        </h2>
        <h2 class="rwygrey--text font-weight-light mb-5">
          Nothing feels better than being valued for your skills<br
            class="hidden-md-and-down"
          />
          and working on the forefront of new things.
        </h2>
        <h2 class="rwygrey--text font-weight-light">
          Being highly employable is within your reach!
        </h2>
        <v-img src="/images/down-arrow.png" max-width="30" class="my-10" />
        <v-btn
          color="secondary"
          plain
          rounded
          x-large
          :to="{ name: 'Register' }"
        >
          TAKE THE TEST NOW!
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-img src="/images/get-ready-person-5.png" />
    </v-row>
  </v-container>
</template>

<style scoped>
.home-banner-lg {
  background: no-repeat top left;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}
.home-banner-sm {
  background: no-repeat 50% 0%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.home-banner-xs {
  background: no-repeat 120% 50%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}

.person-4-lg {
  background: no-repeat top left;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}
.person-4-sm {
  background: no-repeat bottom left;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.person-4-xs {
  background: no-repeat 120% 50%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}
</style>
<script>
import HeaderComponent from '@/components/ui/Header.vue';
import HeaderLogo from '@/components/ui/HeaderLogo.vue';
import TopMenu from '@/components/ui/TopMenu.vue';

export default {
  name: 'GetReadyView',
  components: {
    HeaderComponent,
    HeaderLogo,
    TopMenu,
  },
  methods: {},
  metaInfo: {
    title: 'Get Ready',
  },
};
</script>
