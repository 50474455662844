<template>
  <v-form ref="stripeForm" v-model="valid" class="mt-3">
    <v-row align="center" justify="center">
      <v-col cols="12" align="left">
        <div ref="cardNumber"></div>
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="mt-5">
      <v-col cols="4" align="left">
        <div ref="cardExpiry"></div>
      </v-col>
      <v-col cols="3" align="left">
        <div ref="cardCvc"></div>
      </v-col>
      <v-col cols="5" alignn="left">
        <v-text-field
          label="zipcode"
          :rules="rules.zipcode"
          v-model="cardDetails.zipcode"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" align="left" class="pa-0 ma-0">
        <span class="error--text caption">{{ cardErrors.cardNumber }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" align="left" class="pa-0 ma-0">
        <span class="error--text caption">{{ cardErrors.cardCvc }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" align="left" class="pa-0 ma-0">
        <span class="error--text caption">{{ cardErrors.cardExpiry }}</span>
      </v-col>
    </v-row>
  </v-form>
</template>

<style scoped>
.StripeElement {
  /* box-sizing: border-box; */

  padding: 10px 12px 6px 12px;

  border-bottom: 1px solid rgb(118, 118, 118);
  /* border-radius: 4px; */
  background-color: white;
}

.StripeElement--invalid {
  border-bottom: 1px solid #fa755a;
}

.StripeElement--focus {
  border-bottom: 1px solid black;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>

<script>
export default {
  name: 'StripeForm',
  data: () => ({
    valid: false,
    intentToken: null,
    cardDetails: {},
    validStripeElements: {
      cardNumber: false,
      cardCvc: false,
      cardExpiry: false,
      cardZipcode: false,
    },
    cardErrors: {
      cardNumber: null,
      cardCvc: null,
      cardExpiry: null,
      cardZipcode: null,
    },
    rules: {
      zipcode: [
        (v) => !!v || 'zipcode is required',
        (v) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || 'invalid zipcode',
      ],
    },
  }),
  props: {
    stripe: {
      type: Object,
    },
  },
  mounted: function() {
    let elements = this.stripe.elements();

    // card number element
    let cardNumber = elements.create('cardNumber', {
      showIcon: true,
    });
    cardNumber.mount(this.$refs.cardNumber);
    cardNumber.on('change', (event) => {
      if (event.complete) {
        this.cardErrors.cardNumber = null;
        this.validStripeElements.cardNumber = true;
      } else if (event.error) {
        this.validStripeElements.cardNumber = false;
        this.cardErrors.cardNumber = event.error.message;
      }
      this.validateAll();
    });
    this.cardDetails.number = cardNumber;

    // card cvc element
    this.cardDetails.cvc = elements.create('cardCvc');
    this.cardDetails.cvc.mount(this.$refs.cardCvc);
    this.cardDetails.cvc.on('change', (event) => {
      if (event.complete) {
        this.cardErrors.cardCvc = null;
        this.validStripeElements.cardCvc = true;
      } else if (event.error) {
        this.validStripeElements.cardCvc = false;
        this.cardErrors.cardCvc = event.error.message;
      }
      this.validateAll();
    });

    // card expiry element
    this.cardDetails.expiry = elements.create('cardExpiry');
    this.cardDetails.expiry.mount(this.$refs.cardExpiry);
    this.cardDetails.expiry.on('change', (event) => {
      if (event.complete) {
        this.cardErrors.cardExpiry = null;
        this.validStripeElements.cardExpiry = true;
      } else if (event.error) {
        this.validStripeElements.cardExpiry = false;
        this.cardErrors.cardExpiry = event.error.message;
      }
      this.validateAll();
    });
  },
  watch: {
    cardDetails() {
      this.$emit('form-values', this.cardDetails);
    },
    valid() {
      this.validateAll();
    },
  },
  methods: {
    validateAll() {
      // make sure stripe is validated
      if (
        this.validStripeElements.cardNumber &&
        this.validStripeElements.cardCvc &&
        this.validStripeElements.cardExpiry
      ) {
        this.$emit('validated', this.valid);
      } else {
        this.$emit('validated', false);
      }
    },
  },
};
</script>
