<template>
  <v-container class="pa-0">
    <v-footer padless class="hidden-md-and-up">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="12" class="primary text-center text-h7">
          <v-row no-gutters justify="center" align="center">
            <v-col cols="4" sm="12" justify="center" align="center">
              <v-img
                src="/images/footer-star.png"
                max-height="100"
                max-width="100"
                aspect-ratio="1"
                contain
              />
            </v-col>
            <v-col cols="8" sm="12" class="pr-1">
              © FIND YOUR FUTURE {{ new Date().getFullYear() }}
            </v-col>
            <v-col cols="12" class="text-center text-h7 rwygrey pt-5">
              <v-btn
                text
                rounded
                class="my-2 white--text"
                :to="{ name: 'PrivacyPolicy' }"
              >
                PRIVACY POLICY
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-center rwygrey">
              <v-icon x-large color="red">
                mdi-star
              </v-icon>
            </v-col>
            <v-col cols="12" class="text-center text-h7 rwygrey pb-5 pt-1">
              <v-btn
                text
                rounded
                class="my-2 white--text"
                :to="{ name: 'TermsOfService' }"
              >
                TERMS OF SERVICE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
    <v-footer padless class="hidden-md-and-down">
      <v-row no-gutters>
        <v-col cols="6" md="2" class="primary ">
          <v-img src="/images/footer-star.png" />
        </v-col>
        <v-col cols="6" md="3" class="primary white--text">
          <v-row align="center" justify="center" class="fill-height">
            <v-col cols="12" class="text-center text-h7">
              © FIND YOUR FUTURE {{ new Date().getFullYear() }}
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="7" class="grey pa-2 white--text">
          <v-row align="center" justify="center" class="fill-height">
            <v-col cols="12" md="4" class="text-center text-h7">
              <v-btn
                text
                rounded
                class="my-2 white--text"
                :to="{ name: 'PrivacyPolicy' }"
              >
                PRIVACY POLICY
              </v-btn>
            </v-col>
            <v-col cols="12" md="1" class="text-center">
              <v-icon x-large color="red">
                mdi-star
              </v-icon>
            </v-col>
            <v-col cols="12" md="4" class="text-center text-h7">
              <v-btn
                text
                rounded
                class="my-2 white--text"
                :to="{ name: 'TermsOfService' }"
              >
                TERMS OF SERVICE
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>
